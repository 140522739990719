import React from 'react';
import {Step, AutomaticMailStep, useWorkflowQuery} from 'api';
import Stats from './Stats';
import {useParams, Link} from 'react-router-dom';
import {AlertCircle} from 'components/Ui/Icon';
import StepTypeIcon from 'components/Workflow/Icon/StepTypeIcon';
import Components from '../Components';
import {Tooltip} from 'components/antd';

interface AutomaticMailStepType extends Step {
  steppable: AutomaticMailStep;
}

interface Props {
  step: AutomaticMailStepType;
}

const AutomaticMailStats = ({step}: Props) => {
  const {workflowId, poolId} = useParams<{
    workflowId: string;
    poolId: string;
  }>();
  const {data: {workflow = null} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  return (
    <div className="flex items-center w-full">
      <div className="w-14 flex-shrink-0">
        <Components.Switch step={step} />
      </div>
      <div className="w-24 flex-shrink-0 mr-4">
        <Components.Date step={step} />
      </div>
      <div className="mx-2 h-8">
        <StepTypeIcon step={step} active width="32px" height="32px" />
      </div>
      <div className="flex flex-col min-w-0 flex-grow-1">
        <div className="flex flex-row items-center">
          <Link
            className="overflow-hidden text-ellipsis line-clamp-2 break-all font-bold text-base mr-1"
            to={`/pools/${poolId}/workflows/${workflowId}/steps/${step.uuid}`}>
            {step.name}
          </Link>
          {!step.steppable.automaticMail.bodyHtml && (
            <Tooltip placement="top" title="メールが作成されていません">
              <span className="[&>svg]:mb-[-3px]">
                <AlertCircle />
              </span>
            </Tooltip>
          )}
        </div>
        <div className="flex flex-row items-center">
          <p className="overflow-hidden text-ellipsis line-clamp-1 text-sm text-c-light break-all mr-1 mb-0">
            {step.memo}
          </p>
        </div>
      </div>
      <div className="flex justify-end items-center flex-1 mr-0">
        <Stats step={step} />
        {workflow.status === 'draft' && <Components.Action step={step} />}
      </div>
    </div>
  );
};

export default AutomaticMailStats;
