import React, {useState} from 'react';
import styled from 'styled-components';
import {InputNumber, Radio} from 'components/antd';
import {useWorkflowQuery, useUpdateWorkflowMutation} from 'api';
import {useParams} from 'react-router-dom';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();
  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });
  const [updateWorkflow] = useUpdateWorkflowMutation();
  const [dueDay, setDueDay] = useState(workflow.dueDay);

  return (
    <Container>
      <Item>
        <div>対象</div>
        <Radio.Group
          disabled={workflow.status !== 'draft' || !isMember}
          onChange={(e) =>
            updateWorkflow({
              variables: {
                uuid: workflowId,
                attributes: {
                  target: e.target.value,
                },
              },
            })
          }
          value={workflow.target}>
          <Radio value={'only_new_prospects'}>
            新規に追加登録されたリードのみ対象
            <div>資料ダウンロード・問い合わせフォームなど</div>
          </Radio>

          <Radio value={'only_existing_prospects'}>
            登録済みリードのみ対象
            <div>
              セミナー・展示会参加者、掘り起こし営業、失注先・リサイクル営業など
            </div>
          </Radio>

          <Radio value={'all_prospects'}>すべてのリードが対象</Radio>
        </Radio.Group>
      </Item>
      <Item>
        <div>ステップの期限</div>
        <InputNumber
          disabled={workflow.status !== 'draft' || !isMember}
          value={dueDay}
          onChange={(value) => {
            if (value) {
              setDueDay(value);
              updateWorkflow({
                variables: {
                  uuid: workflowId,
                  attributes: {
                    dueDay: value,
                  },
                },
              });
            }
          }}
          min={1}
          style={{width: '55px', marginRight: '7px'}}
        />
        日
      </Item>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const Item = styled.div`
  margin-bottom: 35px;

  > div:first-child {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .ant-radio-group {
    display: inline-flex;
    flex-direction: column;
    gap: 15px;

    .ant-radio-wrapper {
      > span:last-child {
        font-weight: 500;
        font-size: 14px;
        color: #bdc4cb;

        > div {
          font-weight: 500;
          font-size: 10px;
          color: #bdc4cb;
        }
      }
    }

    .ant-radio-wrapper-checked {
      > span:last-child {
        color: #495058;

        > div {
          color: #899098;
        }
      }
    }
  }
`;
