import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import {StringParam, useQueryParams} from 'use-query-params';
import useMonthHeaders from '../../hooks/useMonthHeaders';
import {
  useLeadRegistrationReportLeadSourceReportsQuery,
  useLeadRegistrationReportSumReportQuery,
} from 'api';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {data: {leadRegistrationReportLeadSourceReports = []} = {}} =
    useLeadRegistrationReportLeadSourceReportsQuery({
      variables: {month: query.month},
      fetchPolicy: 'cache-and-network',
    });
  const {data: {leadRegistrationReportSumReport = null} = {}} =
    useLeadRegistrationReportSumReportQuery({
      variables: {
        month: query.month,
      },
      fetchPolicy: 'cache-and-network',
    });

  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const headers: ReportTableHeader[] = useMonthHeaders(query.month);

  const data: ReportTableData[] = [
    leadRegistrationReportSumReport,
    ...leadRegistrationReportLeadSourceReports,
  ]
    .filter(Boolean)
    .map((report) => {
      return {
        label: report.name,
        values: [sum(report.values), ...report.values],
      };
    });
  return {
    data,
    headers,
  };
};

export default useTableData;
