import React from 'react';
import Modal from './Modal';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useCreateTaskMutation, ProspectPool} from 'api';
import {taskPriorities} from 'helpers/task';
import {Quill} from 'components/Ui';
import styled from 'styled-components';
import {UserSelector} from '../../Task/UserSelector';

interface Props {
  prospectPool: ProspectPool;
  visible: boolean;
  onClose: () => void;
}

export default ({prospectPool, visible, onClose}: Props) => {
  const options = location.pathname.includes('tasks')
    ? {
        onCompleted: onClose,
        refetchQueries: ['tasks'],
      }
    : {
        onCompleted: onClose,
        refetchQueries: ['prospectPools'],
      };

  const [createTask] = useCreateTaskMutation(options);

  const ref = React.useRef(null);

  React.useEffect(() => {
    !formik.values.descriptionHtml &&
      ref.current?.editor.setText(formik.values.description);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      priority: 'normal',
      userId: '',
      description: '',
      descriptionHtml: '',
      dueDate: undefined,
      taskType: 'tel',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required('必須項目です'),
      priority: Yup.string().required('必須項目です'),
      taskType: Yup.string().required('必須項目です'),
      userId: Yup.string().nullable(),
      dueDate: Yup.date().nullable(),
      description: Yup.string().nullable(),
      descriptionHtml: Yup.string().nullable(),
    }),
    onSubmit: (values) =>
      createTask({
        variables: {uuid: prospectPool.uuid, attributes: values},
      }),
  });

  return (
    <Modal title="ToDo新規作成" visible={visible} onClose={onClose}>
      <div className="flex flex-col px-8 py-4">
        <form onSubmit={formik.handleSubmit} className="">
          <div className="flex justify-center">
            <input
              type="text"
              placeholder="タイトル"
              value={formik.values.title}
              onChange={(e) => formik.setFieldValue('title', e.target.value)}
              className="form-input p-2 w-full border-c-border rounded-sm text-sm focus:outline-none"
              required
            />
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="action" className="w-24 mr-5 text-c-light">
              アクション種類
            </label>
            <select
              name="action"
              value={formik.values.taskType}
              onChange={(e) => formik.setFieldValue('taskType', e.target.value)}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              <option value="tel">電話</option>
              <option value="mail">メール</option>
              <option value="other">その他</option>
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="date" className="w-24 mr-5 text-c-light">
              日付
            </label>
            <input
              type="date"
              value={formik.values.dueDate}
              onChange={(date) =>
                formik.setFieldValue('dueDate', date.target.value)
              }
              className="w-36 mr-3 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
            />
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="user" className="w-24 mr-5 text-c-light">
              ToDo担当者
            </label>
            <UserSelector user={null} setValue={formik.setFieldValue} />
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-24 mr-5 text-c-light">
              優先度
            </label>
            <select
              name="priority"
              value={formik.values.priority}
              onChange={(e) => formik.setFieldValue('priority', e.target.value)}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              {taskPriorities.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  優先度: {priority.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-28 mr-7 text-c-light">
              説明
            </label>
            <QuillWrapper onBlur={formik.handleBlur('description_html')}>
              <Quill
                ref={ref}
                theme="snow"
                value={formik.values.descriptionHtml}
                onChange={(content, delta, source, editor) => {
                  formik.setFieldValue('descriptionHtml', content);
                  formik.setFieldValue('description', editor.getText());
                }}
                modules={{
                  toolbar: [
                    [{header: [1, 2, 3, false]}],
                    ['bold', 'italic', 'underline'],
                    ['code-block'],
                    ['link'],
                  ],
                }}
                formats={[
                  'header',
                  'list',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'color',
                  'background',
                  'code-block',
                  'link',
                ]}
                bounds={'#quill-container'}
              />
            </QuillWrapper>
          </div>
          <div className="flex justify-end my-5">
            <button
              type="button"
              onClick={onClose}
              className="cursor-pointer bg-white border border-c-border text-c-light text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light mr-3">
              キャンセル
            </button>
            <button
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light disabled:cursor-default">
              登録
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const QuillWrapper = styled.div`
  width: 100%;
  border: 1px solid #e2e6ea;
  border-radius: 4px;
  .ql-container {
    border: none;
  }
  .ql-container.ql-snow {
    min-height: 50px;
    .ql-editor {
      min-height: 50px;
      overflow-y: scroll;
      resize: vertical;
    }
  }
`;
