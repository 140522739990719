import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import LeadSource from './leadSource';
import {useCurrentUserQuery} from 'api';
import User from './user';
import Category from './category';
import Employee from './Employee';
import Phase from './Phase';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});

  const notImplementedTabs = [
    {
      label: 'フェーズ別',
      to: '/reports/lead/phase',
    },
    {
      label: '業種別',
      to: '/reports/lead/category',
    },
    {
      label: '従業員数',
      to: '/reports/lead/employee',
    },
    {
      label: 'タグ別',
      to: '/reports/lead/tag',
    },
  ];
  return (
    <Layout
      title="リード状況分析"
      tabs={[
        {
          label: '最新リードソース別',
          to: '/reports/lead/lead_source',
        },
        {
          label: '担当者別',
          to: '/reports/lead/user',
        },
        ...(currentUser?.role === 'admin' ? notImplementedTabs : []),
      ]}>
      <Switch>
        <Route path="/reports/lead/lead_source" component={LeadSource} />
        <Route path="/reports/lead/user" component={User} />
        <Route path="/reports/lead/category" component={Category} />
        <Route path="/reports/lead/employee" component={Employee} />
        <Route path="/reports/lead/phase" component={Phase} />
        <Redirect to="/reports/lead/lead_source" />
      </Switch>
    </Layout>
  );
};
