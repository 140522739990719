import {useUpdateWorkflowMutation, Workflow} from 'api';
import React from 'react';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const priorities = [
    {name: '高', value: 'high'},
    {name: '中', value: 'normal'},
    {name: '低', value: 'low'},
  ];
  const [updateWorkflow] = useUpdateWorkflowMutation();

  return (
    <select
      name="priority"
      value={workflow.priority}
      onChange={(e) =>
        updateWorkflow({
          variables: {
            uuid: workflow.uuid,
            attributes: {
              priority: e.target.value,
            },
          },
        })
      }
      className="w-24 block form-select appearance-none bg-white border-c-border px-2 py-1 rounded-sm focus:outline-none text-sm text-c-light">
      {priorities.map((priority) => (
        <option key={priority.value} value={priority.value}>
          優先度: {priority.name}
        </option>
      ))}
    </select>
  );
};
