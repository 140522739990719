import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {StringParam, useQueryParams} from 'use-query-params';
import {useLeadRegistrationReportLeadSourceReportsQuery} from 'api';
import useMonthAxis from '../../hooks/useMonthAxis';
import React from 'react';

const useChartData = (): ReportChart => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {getColor} = useColor();
  const axis = useMonthAxis(query.month);
  const {data: {leadRegistrationReportLeadSourceReports = []} = {}} =
    useLeadRegistrationReportLeadSourceReportsQuery({
      variables: {
        month: query.month,
      },
      fetchPolicy: 'cache-and-network',
    });

  const data = React.useMemo(() => {
    return leadRegistrationReportLeadSourceReports.map((report, index) => {
      return {
        label: report.name,
        color: getColor(index),
        groupId: 'main',
        values: report.values,
      };
    });
  }, [leadRegistrationReportLeadSourceReports]);

  return {
    axis,
    data,
  };
};

export default useChartData;
