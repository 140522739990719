import {ReportTable, ReportTableHeader} from '../../components/Table/types';
import {useProspectReportProspectPoolUserDailyCallUserReportsQuery} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import useMonthHeaders from '../../hooks/useMonthHeaders';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {data: {prospectReportProspectPoolUserDailyCallUserReports = []} = {}} =
    useProspectReportProspectPoolUserDailyCallUserReportsQuery({
      variables: {month: query.month},
      skip: !query.month,
      fetchPolicy: 'cache-and-network',
    });

  const headers: ReportTableHeader[] = useMonthHeaders(query.month);
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const callData = () => {
    return prospectReportProspectPoolUserDailyCallUserReports
      .map((report) => {
        const reportValuesSum = sum(report.values);
        if (reportValuesSum === 0 || reportValuesSum === null) return null;
        return {
          label: report.name,
          values: [reportValuesSum, ...report.values],
          cellProps: {
            style: {
              fontWeight: 'bold',
              color: '#222426',
            },
          },

          subRows: report.prospectTelStatusCategories.map((category) => {
            return {
              label: category.name,
              values: [sum(category.values), ...category.values],
              color: category.color,
              cellProps: {
                style: {
                  backgroundColor: '#F9FAFC',
                  fontWeight: 'bold',
                  color: '#222426',
                },
              },
              subRows: category.prospectTelStatuses.map((telStatus) => {
                return {
                  label: telStatus.name,
                  values: [sum(telStatus.values), ...telStatus.values],
                };
              }),
            };
          }),
        };
      })
      .filter(Boolean);
  };

  const data = [...callData()];

  return {
    data,
    headers,
  } as ReportTable;
};

export default useTableData;
