import gql from 'graphql-tag';

export default gql`
  fragment accountStatusFragment on AccountStatus {
    id
    clientId
    name
    position
    description
  }
`;
