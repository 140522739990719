import {Workflow} from 'api';
import {ArrowDown, DoubleHyphen} from 'components/Ui/Icon';
import React from 'react';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  switch (workflow.priority) {
    case 'high':
      return (
        <div className="flex">
          <span className="flex items-center justify-center w-24 h-8 gap-1 border border-[#EB5757] rounded-2xl text-[#EB5757] bg-[#FFF4F4] font-bold">
            <ArrowDown color="#EB5757" className="transform rotate-180 w-5" />
            優先度高
          </span>
        </div>
      );
    case 'normal':
      return (
        <div className="flex">
          <span className="flex items-center justify-center w-24 gap-1 border border-[#FF9900] rounded-2xl text-[#A66400] bg-[#FFFAE6] font-bold py-1">
            <DoubleHyphen color="#FF9900" className="text-c-[#EB5757] mx-1" />
            優先度中
          </span>
        </div>
      );
    case 'low':
      return (
        <div className="flex">
          <span className="flex items-center justify-center w-24 h-8 gap-1 border border-[#2F79CB] rounded-2xl text-[#09509D] bg-[#EAF4FF] font-bold">
            <ArrowDown color="#2F79CB" className="w-5" />
            優先度低
          </span>
        </div>
      );
  }
  return null;
};
