import {Account, useUpdateAccountMutation} from 'api';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

interface Props {
  account: Account;
}

export default ({account}: Props) => {
  const [updateAccount] = useUpdateAccountMutation({
    refetchQueries: ['activitiesByProspectPool'],
  });
  const [name, setName] = useState(account.name);

  useEffect(() => {
    setName(account.name);
  }, [account]);

  return (
    <Name>
      <input
        value={name}
        className="w-full h-full rounded hover:border hover:border-[#E2E6EA] focus:border focus:border-[#E2E6EA]"
        onChange={(e) => setName(e.target.value)}
        onBlur={(e) =>
          updateAccount({
            variables: {
              id: account.id,
              attributes: {
                name: e.target.value,
              },
            },
          })
        }
      />
    </Name>
  );
};

const Name = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  &:hover {
    background: #f5faff;
  }
`;
