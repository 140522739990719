import React from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../Menu';
import TelStatus from './TelStatus';
import PreleadStatus from './PreleadStatus';
import ProspectTelStatus from './ProspectTelStatus';
import AccountStatus from './AccountStatus';
import {sideMenuWidth} from 'helpers/sideMenu';
import {useCurrentUserQuery} from 'api';

export default () => {
  const history = useHistory();
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});

  return (
    <Body>
      <Menu />
      <Content>
        <Tabs>
          <Tab
            active={location.pathname.includes('/status/prospect_tel_status')}
            onClick={() =>
              history.push(`/settings/status/prospect_tel_status/status`)
            }>
            <span>リードTELステータス</span>
          </Tab>
          <Tab
            active={location.pathname.includes('/status/prelead_status')}
            onClick={() => history.push(`/settings/status/prelead_status`)}>
            <span>プレリードステータス</span>
          </Tab>
          <Tab
            active={location.pathname.includes('/status/tel_status')}
            onClick={() => history.push(`/settings/status/tel_status/tel`)}>
            <span>営業リストTELステータス</span>
          </Tab>
          {currentUser?.role === 'admin' && (
            <Tab
              active={location.pathname.includes('/status/account_status')}
              onClick={() => history.push(`/settings/status/account_status`)}>
              <span>企業ステータス</span>
            </Tab>
          )}
        </Tabs>
        <Switch>
          <Route
            path="/settings/status/prospect_tel_status"
            component={ProspectTelStatus}
          />
          <Route
            path="/settings/status/prelead_status"
            component={PreleadStatus}
          />
          <Route path="/settings/status/tel_status" component={TelStatus} />
          <Route
            path="/settings/status/account_status"
            component={AccountStatus}
          />
        </Switch>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #fff;
  height: calc(100vh - 60px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 70px 90px;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  overflow: scroll;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  border-bottom: 1px solid #e1e6eb;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 97px;

  span {
    margin-right: 32px;
    padding: 9px 0;
    display: block;
    height: 100%;
    border-bottom: ${({active}) => (active ? 'solid 3px #68b5fb' : '')};
    font-weight: bold;
    font-size: 14px;
    color: ${({active}) => (active ? '#495058' : '#bdc4cb')};
  }

  &:hover {
    cursor: pointer;
  }
`;
