import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
// @ts-ignore
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import {Button, Upload, Modal, Select} from 'components/antd';
import {UploadOutlined} from '@ant-design/icons';
import {
  useImportProspectMutation,
  usePoolProspectCustomizeItemsQuery,
  useCurrentUserQuery,
} from 'api';
import {useParams} from 'react-router-dom';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const upload_size_limit = 10000000; // 10MB

const UploadModalComponent = ({visible, onClose}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const [csv, setCsv] = React.useState(null);
  const {data: {currentUser} = {}} = useCurrentUserQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rowCount: 0,
      headers: [],
    },
    onSubmit: (values: any) =>
      importProspect({
        variables: {
          uuid: poolId,
          attributes: values,
        },
      }),
  });

  const handleClose = () => {
    setCsv(null);
    formik.resetForm();
    onClose();
  };

  const [importProspect, {loading}] = useImportProspectMutation({
    refetchQueries: ['pool'],
    onCompleted: () => handleClose(),
  });

  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({
      variables: {
        poolId: poolId,
      },
    });

  const options = React.useMemo(() => {
    const opts = [
      {
        label: 'リード情報',
        options: [
          {value: 'uuid', label: 'リードID', required: false},
          {value: 'last_name', label: '姓', required: true},
          {value: 'first_name', label: '名', required: false},
          {value: 'email', label: 'メールアドレス', required: true},
          {value: 'telephone_number', label: '電話番号', required: false},
          {value: 'section', label: '部署', required: false},
          {value: 'section_position', label: '役職', required: false},
          {value: 'lead_source', label: '最新リードソース', required: false},
          {
            value: 'original_lead_source',
            label: '初回リードソース',
            required: false,
          },
          {value: 'latest_inflow_date', label: '最新流入日', required: false},
          {value: 'original_inflow_date', label: '初回流入日', required: false},
          {value: 'user_name', label: 'リード担当者', required: false},
          {value: 'stage', label: 'ステージ', required: false},
          {value: 'phase', label: 'フェーズ', required: false},
          {value: 'tag_names', label: 'タグ', required: false},
          {value: 'is_unsubscribed', label: '配信停止', required: false},
          {value: 'comment', label: 'コメント', required: false},
          {
            value: 'activity_user_name',
            label: 'コメント登録ユーザー',
            required: false,
          },
        ],
      },
      {
        label: '会社情報',
        options: [
          {value: 'account_name', label: '会社名', required: true},
          {value: 'web_site', label: 'URL', required: false},
          {value: 'address', label: '住所', required: false},
          {
            value: 'account_telephone_number',
            label: '代表電話',
            required: false,
          },
          {value: 'corp_number', label: '法人番号', required: false},
        ],
      },
    ];

    if (poolProspectCustomizeItems.length > 0) {
      const customizeOptions = poolProspectCustomizeItems.map(
        (customizeItem) => ({
          value: `pool_prospect_customize_item_id_${customizeItem.id}`,
          label: customizeItem.name,
          required: false,
        }),
      );
      opts.push({
        label: 'カスタマイズ項目',
        options: customizeOptions,
      });
    }

    return opts;
  }, [poolProspectCustomizeItems, currentUser]);

  const checkHeaders = () =>
    ['last_name', 'account_name', 'email'].every((header) =>
      formik.values.headers.includes(header),
    );

  return (
    <UploadModal
      destroyOnClose
      title="ファイルのアップロード"
      maskClosable={false}
      visible={visible}
      width={750}
      centered
      bodyStyle={{height: '70vh'}}
      footer={[
        <CancelButton size="large" key="cancel" onClick={handleClose}>
          キャンセル
        </CancelButton>,
        <OkButton
          size="large"
          key="submit"
          type="primary"
          disabled={!formik.isValid || !csv || !checkHeaders()}
          onClick={() => formik.handleSubmit(formik.values)}
          loading={loading}>
          登録
        </OkButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <div className="relative">
          <input type="checkbox" id="more_trigger" className="peer hidden" />
          <div className="h-[300px] peer-checked:h-auto overflow-hidden">
            <h2 className="mb-4 border-l-[3px] border-[#495058] pl-2 text-left font-bold text-[14px] leading-[0.9rem]">
              項目仕様について
            </h2>
            <table
              className="
                text-[12px]
                [&_tr]:border
                [&_*]:border-[#E2E6EA]
                [&_th]:text-center
                [&_th]:p-2
                [&_td]:p-2
                [&_td]:text-[#495058]
                mb-8
                w-full
              ">
              <thead className="bg-[#fafbfb]">
                <tr>
                  <th className="border-r w-[150px]">項目</th>
                  <th>説明</th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    head: 'リードID',
                    body: 'リードIDは、LEADPADに登録したリードに対して付与されるユニークなIDです。\nCSVダウンロード時に、リードIDを取得することが可能です。',
                  },
                  {
                    head: 'タグ',
                    body: '複数のタグを付ける際は、カンマで区切って入力します。',
                  },
                  {
                    head: '配信停止',
                    body: '配信停止の制御が可能です。\n配信停止: “true” ／ 配信停止を解除: “false”',
                  },
                  {
                    head: 'コメント',
                    body: 'タイムラインのコメントとして登録することが可能です。',
                  },
                  {
                    head: 'コメント登録ユーザー',
                    body: 'タイムラインのコメント登録ユーザーとして登録することが可能です。',
                  },
                ].map((noteTableContent, index) => (
                  <tr key={index}>
                    <td className="border-r">{noteTableContent.head}</td>
                    <td className="whitespace-pre">{noteTableContent.body}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h2 className="mb-4 border-l-[3px] border-[#495058] pl-2 text-left font-bold text-[14px] leading-[0.9rem]">
              必須項目について
            </h2>
            <p className="mb-4 text-[12px]">
              アップロードするCSVファイルは、「姓」「メールアドレス」「会社名」が必須項目となります
            </p>
            <h2 className="mb-4 border-l-[3px] border-[#495058] pl-2 text-left font-bold text-[14px] leading-[0.9rem]">
              名寄せについて
            </h2>
            <p className="mb-4 text-[12px]">
              CSVファイルでのリード登録は、CSV各行の内容により、名寄せができる場合は「更新」、名寄せができない場合は「新規登録」となります
            </p>
            {[
              {
                title: '更新',
                list: [
                  'リード情報の更新は、「リードID」を指定する方法と、リード情報でリードを特定する方法があります',
                  'リード情報にメールアドレスがある場合は、「姓」「名」「メールアドレス」でリードを特定します',
                  'リード情報にメールアドレスがない場合は、「姓」「名」「電話番号」でリードを特定します',
                ],
              },
              {
                title: '新規登録',
                list: ['「姓」「会社名」が未入力の行は、登録対象外となります'],
              },
            ].map((item, i) => (
              <React.Fragment key={i}>
                <h3 className="text-[12px] mb-1.5 font-bold text-[#495058]">
                  {item.title}
                </h3>
                <ul className="text-[12px] mb-4 text-[#495058]">
                  {item.list.map((listItem, index) => (
                    <li key={index}>{listItem}</li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
          <div className="peer-checked:hidden inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-white from-10% h-[200px] pointer-events-none absolute"></div>
          <div className="relative flex justify-center mb-4 peer-checked:hidden">
            <label
              htmlFor="more_trigger"
              className="p-4 text-[#899098] text-[14px]">
              もっとみる
              <span className="pointer-events-none inset-y-0 items-center pr-2 inline relative top-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5">
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </label>
          </div>
          <div className="relative hidden justify-center mb-4 peer-checked:flex">
            <label
              htmlFor="more_trigger"
              className="p-4 text-[#899098] text-[14px]">
              閉じる
              <span className="pointer-events-none inset-y-0 items-center pr-2 inline relative top-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 rotate-180">
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </label>
          </div>
        </div>
        <div className="flex justify-center">
          <Upload
            name={'file'}
            accept=".csv"
            showUploadList={false}
            customRequest={() => false}
            onChange={(info) => {
              const file = info.file.originFileObj;
              if (file.size > upload_size_limit) {
                alert(
                  'アップロードしようとしているファイルの容量が10MBを超えています。\n1ファイル10MBまでのアップロードが可能です。',
                );
                return null;
              }
              formik.setFieldValue('file', file);
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const codes = new Uint8Array(e.target.result);
                const encoding = Encoding.detect(codes);
                let unicodeString;
                if (encoding === 'UNICODE') {
                  unicodeString = new TextDecoder().decode(codes);
                } else {
                  unicodeString = Encoding.convert(codes, {
                    to: 'UNICODE',
                    from: encoding,
                    type: 'string',
                  });
                }
                Papa.parse(unicodeString, {
                  dynamicTyping: true,
                  skipEmptyLines: true,
                  complete: (result: any) => {
                    setCsv(result.data);
                    formik.setFieldValue(
                      'headers',
                      new Array(result.data[0].length),
                    );
                    formik.setFieldValue('rowCount', result.data.length);
                  },
                });
              };
              reader.readAsArrayBuffer(file);
            }}>
            <UploadButton size="large">
              <UploadOutlined />
              ファイルのアップロード
            </UploadButton>
          </Upload>
        </div>
        {csv && (
          <>
            <ListRow>
              <div className="column">CSVの項目</div>
              <div>システム項目</div>
            </ListRow>
            {csv[0].map((header: string, index: number) => (
              <ListRow key={index}>
                <div className="column">{header}</div>
                <div className="value">
                  <Select
                    style={{width: '100%'}}
                    value={formik.values.headers[index]}
                    onChange={(value) => {
                      let headers = formik.values.headers;
                      headers[index] = value;
                      formik.setFieldValue('headers', headers);
                    }}>
                    <Select.Option key="none" value="" label="">
                      {''}
                    </Select.Option>
                    {options.map((option) => (
                      <Select.OptGroup key={option.label} label={option.label}>
                        {option.options.map((item) => (
                          <Select.Option
                            key={item.value}
                            value={item.value}
                            disabled={formik.values.headers.includes(
                              item.value,
                            )}>
                            {item.label}
                            {item.required && (
                              <sup className="text-[8px] text-[#FF8F00]">※</sup>
                            )}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    ))}
                  </Select>
                </div>
              </ListRow>
            ))}
          </>
        )}
      </ModalContent>
    </UploadModal>
  );
};

const UploadModal = styled(Modal)`
  &&& {
    .ant-modal-body {
      overflow: scroll;
      padding: 24px 48px;
    }
    .ant-modal-title {
      text-align: center;
      font-weight: bold;
    }
  }
`;

const ModalContent = styled.div`
  > p {
    font-size: 14px;
  }

  .ant-upload {
    margin-bottom: 2rem;
  }
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  border-top: 1px solid #e2e6ea;

  > div {
    &:first-child {
      width: 250px;
    }
    &:last-child {
      flex: 1;
    }
  }

  &:first-child {
    font-weight: bold;
    > div {
      text-align: center;
    }
  }

  &:last-child {
    border-bottom: 1px solid #e2e6ea;
  }
`;

const UploadButton = styled(Button)`
  color: #91ceff;
  border-color: #91ceff;
  &:hover {
    color: #91ceff;
    border-color: #91ceff;
    background: #f8fbff;
  }
  &&& {
    border-radius: 4px;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const OkButton = styled(Button)`
  &&& {
    border: none;
    border-radius: 4px;
  }
`;

export default UploadModalComponent;
