import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {useMainCategoryLeadSourceReportsQuery} from 'api';
import React from 'react';
import useLeadSourceHeaders from './useLeadSourceHeaders';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = useLeadSourceHeaders();
  const {data: {mainCategoryLeadSourceReports = []} = {}} =
    useMainCategoryLeadSourceReportsQuery({});
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const reportData = React.useMemo(() => {
    return mainCategoryLeadSourceReports.map((report) => {
      return {
        label: report.name,
        values: [sum(report.values), ...report.values],
      };
    });
  }, [mainCategoryLeadSourceReports]);

  const data: ReportTableData[] = [...reportData].filter(Boolean);

  return {
    data,
    headers,
  };
};

export default useTableData;
