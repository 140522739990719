import {useLeadSourcesQuery} from 'api';
import {ReportTableHeader} from '../../../components/Table/types';

function useUserHeaders(): ReportTableHeader[] {
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({});
  const leadSourcesHeader = leadSources.map((leadSource) => {
    return leadSource.name;
  });

  return ['従業員数', '合計', '未設定', ...leadSourcesHeader];
}

export default useUserHeaders;
