import {useProspectPooslUsersQuery} from 'api';
import {ReportTableHeader} from '../../../components/Table/types';

function useUserHeaders(): ReportTableHeader[] {
  const {data: {prospectPoolsUsers = []} = {}} = useProspectPooslUsersQuery({});
  const usersHeader = prospectPoolsUsers.map((user) => {
    return [user.lastName, user.firstName].filter(Boolean).join(' ') ?? '-';
  });

  return ['フェーズ', '合計', '未設定', ...usersHeader];
}

export default useUserHeaders;
