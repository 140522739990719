import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Breadcrumb} from 'components/antd';
import {Salesforce, Tel, Edit} from 'components/Ui/Icon';
import {ChevronRightIcon} from '@heroicons/react/24/outline';

export default () => {
  const {poolId, accountId} = useParams<{
    poolId: string;
    accountId: string;
  }>();

  const active = 'font-bold text-[#495058] bg-[#FFFFFF]';
  const inActive = 'hover:text-[#495058] hover:bg-[#FAFBFB] text-[#BDC4CB]';

  return (
    <div className="p-8 bg-c-bg overflow-y-auto h-[calc(100vh-60px)]">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/pools/${poolId}/accounts/${accountId}`}>
            〈 一覧に戻る
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <header className="flex flex-col gap-2 items-start my-6">
        <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
          アプローチ
        </span>
        <h1 className="text-xl font-bold m-0 leading-none">株式会社ロケッツ</h1>
        <a className="leading-none" href="#">
          https://leadpad.io/
        </a>
      </header>
      <div className="flex my-4">
        <div className="flex border-none bg-[#EBEDF2] p-1 cursor-pointer">
          <div className={`flex rounded-sm px-6 py-1 ${active}`}>概要</div>
          <div className={`flex rounded-sm px-6 py-1 ${inActive}`}>リード</div>
          <div className={`flex rounded-sm px-6 py-1 ${inActive}`}>商談</div>
          <div className={`flex rounded-sm px-6 py-1 ${inActive}`}>
            シグナル
          </div>
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex-1 flex flex-col gap-4">
          <div className="bg-white border border-c-border rounded">
            <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
              リード
            </h2>
            <div className="px-4">
              <table className="w-full text-sm text-left">
                <thead className="text-xs border-b border-c-border text-c-light">
                  <tr>
                    <th scope="col" className="px-4 py-1">
                      リード
                    </th>
                    <th scope="col" className="px-4 py-1">
                      ステータス
                    </th>
                    <th scope="col" className="px-4 py-1">
                      役職
                    </th>
                    <th scope="col" className="px-4 py-1">
                      Salesforce
                    </th>
                    <th scope="col" className="px-4 py-1">
                      最終アプローチ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(5)].map((e, i) => (
                    <tr className="border-b border-c-border">
                      <th
                        scope="row"
                        className="px-4 py-2 font-normal whitespace-nowrap">
                        <a href="#">佐藤 太郎</a>
                      </th>
                      <td className="px-4">
                        <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
                          アプローチ
                        </span>
                      </td>
                      <td className="px-4 py-2">営業部長</td>
                      <td className="px-4 py-2">
                        <div className="flex w-max items-center gap-2 [&>svg]:w-4">
                          <Salesforce />
                          <div className="flex items-center gap-1">
                            <span className="flex px-2 h-5 items-center gap-1 rounded-sm border-none bg-[#DCEEFF] text-xs font-bold text-[#005388]">
                              商談
                              <span className="flex h-3 items-center rounded-full bg-[#C7E0EF] px-1 text-xs font-bold text-[#005388]">
                                0
                              </span>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-2">
                        <div className="flex items-center gap-1">
                          <span className="flex px-2 h-5 items-center gap-1 rounded-sm border-none bg-c-bg text-xs text-c-light font-bold [&>svg]:w-3">
                            <Tel />
                            商談
                          </span>
                          3時間前
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="text-right py-2">
                <a>もっとみる</a>
              </div>
            </div>
          </div>
          <div className="bg-white border border-c-border rounded">
            <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
              事業概要
            </h2>
            <div className="p-4 flex flex-col gap-8">
              <div>
                Rocketsは、営業活動を自動化するセールスエンゲージメントプラットフォーム「LEADPAD」を開発・提供しています。
                LEADPADは以下の課題を解決します:
                <ul className="list-disc list-inside">
                  <li>SFA/CRMに大量のリードが放置されている </li>
                  <li>MAツールを導入しているが、施策がメールマガジンのみ</li>
                  <li>アプローチの優先順位が担当者任せになっている</li>
                </ul>
                LEADPADは、培ったノウハウとテクノロジーにより、「1クリックで放置顧客をゼロにして商談を創出する」ことを可能にします。
              </div>

              <div>
                <dl className="text-sm">
                  <dt className="text-c-light">郵便番号</dt>
                  <dd className="mb-2">111-1111</dd>
                  <dt className="text-c-light">住所</dt>
                  <dd className="mb-2">東京都中央区日本橋1-1-1</dd>
                  <dt className="text-c-light">住所</dt>
                  <dd className="mb-2">東京都中央区日本橋1-1-1</dd>
                  <dt className="text-c-light">住所</dt>
                  <dd className="mb-2">東京都中央区日本橋1-1-1</dd>
                  <dt className="text-c-light">住所</dt>
                  <dd className="mb-2">東京都中央区日本橋1-1-1</dd>
                  <dt className="text-c-light">住所</dt>
                  <dd className="mb-2">東京都中央区日本橋1-1-1</dd>
                </dl>
                <div className="text-center py-2">
                  <a>すべて表示する</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-96">
          <div className="bg-white border border-c-border rounded">
            <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
              事業概要
            </h2>
            <div className="p-4">
              <div className="flex flex-col text-sm gap-2">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-c-light">企業ステータス</div>
                    <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
                      アプローチ
                    </span>
                  </div>
                  <button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
                    <Edit className="w-5 h-5 text-c-primary" />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-c-light">担当者</div>
                    <span>山田　太郎</span>
                  </div>
                  <button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
                    <Edit className="w-5 h-5 text-c-primary" />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-c-light">タグ</div>
                    <div className="flex gap-2">
                      <span className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white bg-[#48938A]">
                        Hubspot導入企業
                      </span>
                      <span className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white bg-[#E5613D]">
                        Salesforce導入企業
                      </span>
                    </div>
                  </div>
                  <button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
                    <Edit className="w-5 h-5 text-c-primary" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
