import React, {useState, useRef} from 'react';
import linkifyHtml from 'linkify-html';
import styled from 'styled-components';
import {Cross, AddPaperFile} from 'components/Ui/Icon';
import {
  Button,
  Form,
  Select,
  Input,
  Upload,
  Tooltip,
  Popover,
} from 'components/antd';
import {
  useCreateManualMailProspectActivityMutation,
  useUsersByClientAllQuery,
  useProspectPoolQuery,
  ProspectPool,
  useUploadMailImageMutation,
  useActionsByProspectPoolQuery,
  useCurrentUserQuery,
} from 'api';
import {Quill as ReactQuill} from 'components/Ui';
import {Quill} from 'react-quill';
import {prospectStages} from 'helpers/prospectStages';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import mailVariableOptions from 'helpers/mailVariableOptions';
import {useLocation} from 'react-router-dom';
import useMailTemplate from 'hooks/useMailTempate';
import FieldFrom from './FieldFrom';

interface Props {
  setIsNewMail: (isNewMail: boolean) => void;
  prospectPool: ProspectPool;
}

export default ({setIsNewMail, prospectPool}: Props) => {
  const {templates, templateTitle} = useMailTemplate();
  const [areCcAndBccSelectable, setAreCcAndBccSelectable] = useState(false);
  const [variablePopoverVisible, setVariablePopoverVisible] =
    React.useState(false);

  const ref = useRef(null);

  const onClose = () => setIsNewMail(false);

  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };

  const [createManualMailProspectActivity, {loading}] =
    useCreateManualMailProspectActivityMutation({
      onCompleted: onClose,
      refetchQueries: ['actionCounts', 'actions'],
    });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  const {data: {prospectPool: {prospect: {email} = {}} = {}} = {}} =
    useProspectPoolQuery({
      variables: {uuid: prospectPool.uuid},
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });

  const location = useLocation<{actionUuid: string}>();

  const convertBodyHtml = (text: string) =>
    convertUrlToLink(convertClassToInlineStyle(text));

  const convertClassToInlineStyle = (text: string) =>
    text.replace(/class="ql-align-center"/g, 'style="text-align: center"');

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionUuid: null,
      fromEmail: currentUser?.email || '',
      fromName: currentUser?.lastName
        ? `${currentUser?.lastName} ${currentUser?.firstName}`
        : '',
      stage: currentStageValue()?.id,
      to: email,
      cc: [],
      bcc: [],
      subject: '',
      bodyText: '',
      bodyHtml: '',
      files: [],
    },
    validationSchema: Yup.object().shape({
      fromEmail: Yup.string().email().required(),
      to: Yup.string().email().required(),
      subject: Yup.string().required(),
      bodyText: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const actionUuid = values.actionUuid;
      delete values.actionUuid;

      values.bodyHtml = convertBodyHtml(values.bodyHtml);

      createManualMailProspectActivity({
        variables: {
          uuid: prospectPool.uuid,
          actionUuid: actionUuid,
          attributes: values,
        },
      });
    },
  });

  React.useEffect(() => {
    if (location?.state?.actionUuid) {
      formik.setFieldValue('actionUuid', location?.state?.actionUuid);
    }
  }, [location?.state]);

  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };

  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  Quill.register(fontSizeStyle, true);

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            'bold',
            'italic',
            'underline',
            {align: ['', 'center']},
            {color: []},
            'link',
            'image',
            {size: fontSizeStyle.whitelist},
          ],
        ],
      },
    };
  }, []);

  React.useEffect(() => {
    // 初回レンダリング時にformik.isValidがtrueになってしまうので、falseになるようにしている。
    (() => formik.validateForm())();
  }, []);

  const {data: {actionsByProspectPool = []} = {}} =
    useActionsByProspectPoolQuery({
      variables: {
        prospectPoolUuid: prospectPool.uuid,
        status: 'open',
      },
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const openManualMailActions = actionsByProspectPool.filter(
    (action) => action.actionableType === 'Action::ManualMail',
  );

  return (
    <Container>
      <Header>
        <h4>Eメールの作成</h4>
        <CloseButton onClick={onClose}>
          <Cross />
        </CloseButton>
      </Header>

      <Form onFinish={formik.handleSubmit}>
        <FieldGroup>
          <Field>
            <label>ステップ</label>
            <Select
              value={formik.values.actionUuid}
              onChange={(value) => formik.setFieldValue('actionUuid', value)}>
              <Select.Option value={null}>未選択</Select.Option>
              {openManualMailActions?.map((action) => (
                <Select.Option value={action.uuid} key={action.uuid}>
                  {action.step.name}
                </Select.Option>
              ))}
            </Select>
          </Field>

          <Field>
            <label>ステージ</label>
            <Select
              value={formik.values.stage}
              onChange={(value) => formik.setFieldValue('stage', value)}>
              {prospectStages.map((stage) => (
                <Select.Option key={stage.id} value={stage.id}>
                  {stage.displayName}
                </Select.Option>
              ))}
            </Select>
          </Field>
        </FieldGroup>

        <FieldGroup>
          <div style={{padding: '0 15px'}}>
            {areCcAndBccSelectable ? (
              <>
                <Field>
                  <label>Cc</label>
                  <Select
                    mode="tags"
                    bordered={false}
                    value={formik.values.cc}
                    onChange={(values: [string]) =>
                      formik.setFieldValue(
                        'cc',
                        values.filter((value) =>
                          Yup.string().trim().email().isValidSync(value),
                        ),
                      )
                    }>
                    {users.map((user) => (
                      <Select.Option key={user.uuid} value={user.email}>
                        {user.lastName} {user.firstName}
                        {`<${user.email}>`}
                      </Select.Option>
                    ))}
                  </Select>
                </Field>

                <Field>
                  <label>Bcc</label>
                  <Select
                    mode="tags"
                    bordered={false}
                    value={formik.values.bcc}
                    onChange={(values: [string]) =>
                      formik.setFieldValue(
                        'bcc',
                        values.filter((value) =>
                          Yup.string().trim().email().isValidSync(value),
                        ),
                      )
                    }>
                    {users.map((user) => (
                      <Select.Option key={user.uuid} value={user.email}>
                        {user.lastName} {user.firstName}
                        {`<${user.email}>`}
                      </Select.Option>
                    ))}
                  </Select>
                </Field>
              </>
            ) : (
              <Field
                onClick={() => setAreCcAndBccSelectable(true)}
                style={{cursor: 'pointer'}}>
                <label>Cc Bcc</label>
              </Field>
            )}
            <FieldFrom formik={formik} />
            <Field>
              <label>件名</label>
              <Input
                id="subject"
                name="subject"
                value={formik.values.subject}
                bordered={false}
                onChange={(e) =>
                  formik.setFieldValue('subject', e.target.value)
                }
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />
            </Field>
          </div>

          <div
            style={{display: 'flex', alignItems: 'center', paddingTop: '15px'}}>
            <label
              style={{
                display: 'inline-block',
                paddingLeft: '15px',
              }}>
              本文
            </label>
            <Select
              style={{
                display: 'block',
                margin: '0 15px 0 auto',
                width: '360px',
              }}
              placeholder="メールテンプレート"
              optionFilterProp="children"
              showSearch
              onSelect={(value: any) => {
                const template = templates.find(
                  (template) => template.uuid === value,
                );
                formik.setValues({
                  ...formik.values,
                  subject: template.mailTemplate.title,
                });
                template.mailTemplate.bodyHtml
                  ? (document.getElementsByClassName('ql-editor')[0].innerHTML =
                      template.mailTemplate.bodyHtml)
                  : ref.current?.editor.setText(template.mailTemplate.body);
              }}>
              {templates.map((template) => (
                <Select.Option key={template.uuid} value={template.uuid}>
                  {templateTitle(template)}
                </Select.Option>
              ))}
            </Select>
          </div>

          <ReactQuill
            ref={ref}
            theme="snow"
            value={formik.values.bodyHtml}
            placeholder="本文を記入してください。"
            onChange={(content, delta, source, editor) => {
              formik.setFieldValue('bodyHtml', content);
              formik.setFieldValue('bodyText', editor.getText());
            }}
            onBlur={() => {
              const converted = convertUrlToLink(formik.values.bodyHtml);
              formik.setFieldValue('bodyHtml', converted);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                const converted = convertUrlToLink(formik.values.bodyHtml);
                formik.setFieldValue('bodyHtml', converted);
              }
            }}
            modules={modules}
            bounds={'#quill-container'}
          />
        </FieldGroup>
        {formik.values.files.map((file) => (
          <File key={file.uid}>
            <FileName>{file.name}</FileName>
            <div
              onClick={() =>
                formik.setFieldValue(
                  'files',
                  formik.values.files.filter((f) => f !== file),
                )
              }>
              <Cross />
            </div>
          </File>
        ))}
        <Actions>
          <Popover
            content={
              <Content>
                {mailVariableOptions.map((option) => (
                  <PopoverSelectButtonWrapper key={option.code}>
                    <PopoverSelectButton
                      onClick={() => {
                        if (ref.current?.editor.getSelection()) {
                          ref.current?.editor.insertText(
                            ref.current?.editor.getSelection().index,
                            option.code,
                          );
                        } else {
                          const subject: any =
                            document.getElementById('subject');
                          const value =
                            subject.value.substr(0, subject.selectionStart) +
                            option.code +
                            subject.value.substr(subject.selectionStart);
                          formik.setFieldValue('subject', value);
                        }
                      }}>
                      <span>{option.code}</span>
                      <span>{option.title}</span>
                    </PopoverSelectButton>
                  </PopoverSelectButtonWrapper>
                ))}
              </Content>
            }
            trigger="click"
            visible={variablePopoverVisible}
            placement="topRight"
            onVisibleChange={setVariablePopoverVisible}>
            <PopoverDisplayButton>{`{ }`}</PopoverDisplayButton>
          </Popover>
          <Upload
            name={'files'}
            customRequest={() => false}
            showUploadList={false}
            onChange={(info) => {
              const addedFile = info.file.originFileObj;
              const upload_size_limit = 25000000; // 25MB

              let fileSize = addedFile.size;
              formik.values.files.forEach((file) => (fileSize += file.size));
              if (fileSize > upload_size_limit) {
                alert(
                  'ファイルの容量が25MBを超えています。\n合計25MBまでの添付が可能です。',
                );
                return null;
              }

              formik.setFieldValue('files', [
                ...formik.values.files,
                addedFile,
              ]);
            }}>
            <Tooltip
              title="ファイルを添付(25MBまで)"
              color={'#222426'}
              placement="top">
              <Button icon={<AddPaperFile />} style={{marginRight: 2}} />
            </Tooltip>
          </Upload>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
            loading={loading}>
            送信
          </Button>
        </Actions>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #e2e6ea;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  label {
    color: #899098;
    font-weight: 500;
    font-size: 12px;
  }

  .ql-toolbar {
    border: none;
    position: absolute;
    bottom: 40px;
    transform: translateY(100%);
  }
  .ql-snow {
    border: none;
    padding: 0;
  }
  .ql-editor {
    padding: 10px 15px;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }

  .ql-snow .ql-picker.ql-size {
    width: 50px;

    .ql-picker-label {
      padding-left: 0;
    }

    .ql-picker-options {
      width: 50px;
    }
  }
`;

const Header = styled.div`
  background: #f3f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  h4 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
`;

const Field = styled.div`
  flex: 1;
  min-height: 45px;
`;

const FieldGroup = styled.div`
  &:first-child {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid #e1e6eb;

    label {
      display: block;
    }

    &&& {
      .ant-select {
        width: 210px;
      }
    }
  }

  .ql-picker-label {
    padding-left: 15px;
  }

  &:nth-child(2) {
    ${Field} {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e6eb;
    }

    label {
      width: 56px;
    }

    &&& {
      .ant-select,
      .ant-input {
        width: 100%;
        max-width: 320px;
      }
    }
  }
`;

const CloseButton = styled.div`
  cursor: pointer;

  svg {
    width: 8px;
    height: 8px;

    path {
      stroke: #495058;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 2px 10px 0;
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;
    path {
      stroke: #899098;
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: #68b5fb;
`;

const PopoverDisplayButton = styled(Button)`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  color: #899098;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const PopoverSelectButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span:first-child {
    color: #899098;
    margin-right: 10px;
  }
  span:last-child {
    color: #495058;
  }
`;

const PopoverSelectButton = styled(Button)`
  padding: 0;
  padding-left: 20px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;
