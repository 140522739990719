import React from 'react';
import useChartData from './useChartData';
import {VerticalBarChart} from '../../components/Chart';
import {Dropdown} from '../../components/Dropdown';
import {useCurrentUserQuery} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import Table from './Table';

export default () => {
  const [query, setQuery] = useQueryParams({
    column: StringParam,
  });
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const chart = useChartData();

  const dropdownItems = [
    {label: '合計', value: 'sum'},
    {label: 'リードソース別', value: 'leadSource'},
    {label: '担当者別', value: 'user'},
  ];
  const chartHeight = React.useMemo(() => {
    return chart.axis.length * 60;
  }, [chart]);

  return (
    <div className="flex flex-col">
      <div className="border border-c-border">
        <VerticalBarChart
          chart={chart}
          legend={false}
          style={{height: `${chartHeight}px`}}
          yAxisProps={{width: 240}}
        />
        <div className="p-8 border-t border-c-border flex flex-col gap-2">
          {currentUser?.role === 'admin' && (
            <Dropdown
              value={query.column ?? 'sum'}
              items={dropdownItems}
              onSelect={(value) => setQuery({column: value})}
            />
          )}
          <Table />
        </div>
      </div>
    </div>
  );
};
