import React from 'react';
import {
  Workflow,
  useCopyWorkflowMutation,
  useCurrentUserQuery,
  useDestroyWorkflowMutation,
} from 'api';
import moment from 'moment';
import {Link, useParams, useHistory} from 'react-router-dom';
import {Popover} from 'antd';
import {DotMenu, Copy, Trash} from 'components/Ui/Icon';
import Stats from './Stats';
import useClientUser from 'hooks/useClientUser';
import Priority from './Priority';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {poolId} = useParams<{poolId: string}>();
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
  const history = useHistory();
  const {isMember} = useClientUser();

  const [copyWorkflow] = useCopyWorkflowMutation({
    variables: {workflowUuid: workflow.uuid},
    refetchQueries: ['workflows'],
    onCompleted: () => {
      history.push(`/pools/${poolId}/workflows?page=1&status=draft`);
    },
  });

  const [destroyWorkflow] = useDestroyWorkflowMutation({
    variables: {workflowUuid: workflow.uuid},
    refetchQueries: ['workflows'],
    onCompleted: () => {
      history.push(`/pools/${poolId}/workflows?page=1&status=draft`);
    },
  });

  const Content = () => {
    return (
      <div className="w-32 cursor-pointer h-18 pl-18 mb-0 pt-4 pb-4">
        <div
          className="flex justify-center items-center gap-2 w-full pt-2 h-11 hover:bg-[#f5f5f5]"
          onClick={() => {
            copyWorkflow();
            setIsPopoverVisible(false);
          }}>
          <Copy />
          <span className="font-bold">コピー</span>
        </div>
        {workflow.status === 'draft' && (
          <div
            className="flex justify-center items-center gap-2 w-full pt-2 h-11 hover:bg-[#f5f5f5]"
            onClick={() => {
              destroyWorkflow();
              setIsPopoverVisible(false);
            }}>
            <Trash />
            <span className="font-bold pr-2">削除</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2 flex-1">
          {workflow.status === 'open' && (
            <span className="bg-c-green w-2 h-2 rounded-full" />
          )}
          {workflow.status === 'draft' && (
            <span className="bg-c-lighter w-2 h-2 rounded-full" />
          )}
          {workflow.status === 'closed' && (
            <span className="bg-c-purple w-2 h-2 rounded-full" />
          )}
          <Link
            className="text-base"
            to={`/pools/${poolId}/workflows/${workflow.uuid}`}>
            {workflow.name}
          </Link>
        </div>
        <div>
          <span className="text-c-lighter mr-1">最終更新日</span>
          <span className="text-c-light">
            {moment(workflow.updatedAt).format('YYYY/MM/DD')}
          </span>
        </div>
        <div>
          {isMember && (
            <Popover
              placement="bottomLeft"
              content={<Content />}
              trigger="click"
              onVisibleChange={(visible) => setIsPopoverVisible(visible)}
              visible={isPopoverVisible}>
              <div className="rotate-90 leading-[25.2px] mr-4">
                <DotMenu />
              </div>
            </Popover>
          )}
        </div>
      </div>
      {currentUser.role === 'admin' && <Priority workflow={workflow} />}
      <Stats workflow={workflow} />
    </div>
  );
};
