import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {usePhaseReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();
  const {data: {phaseReports = []} = {}} = usePhaseReportsQuery({});

  const phaseNames = phaseReports
    .filter((report) => !!report.values[0])
    .map((report) => report.name);

  const phaseValues = phaseReports
    .map((report) => report.values)
    .flat()
    .filter(Boolean);

  return {
    axis: phaseNames,
    data: [
      {
        label: 'リード数',
        color: getColor(5),
        values: phaseValues,
      },
    ],
  };
};

export default useChartData;
