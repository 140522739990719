import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Prospects from './prospects';
import Workflows from './workflows';
import Action from './action';
import Accounts from './accounts';
import Account from './accounts/detail';
import AccountGroups from './account_groups';
import Pool from '../../settings/pools/Pool';
import ProspectReport from './prospect_report';

export default () => (
  <Switch>
    <Route path="/pools/:poolId/prospects" component={Prospects} />
    <Route path="/pools/:poolId/workflows" component={Workflows} />
    <Route path="/pools/:poolId/action" component={Action} />
    <Route path="/pools/:poolId/accounts/:accountId" component={Account} />
    <Route path="/pools/:poolId/accounts" component={Accounts} />
    <Route path="/pools/:poolId/account_groups" component={AccountGroups} />
    <Route path="/pools/:poolId/prospect_report" component={ProspectReport} />
    <Route path="/pools/:poolId" component={Pool} />
  </Switch>
);
