import React, {useEffect, useState} from 'react';
import {Button, Input, Select} from 'components/antd';
import styled from 'styled-components';
import {Popover} from 'antd';
import {useUsersByClientAllQuery} from 'api';

interface Props {
  formik: any;
}

export default ({formik}: Props) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [selectEmailValue, setSelectEmailValue] = useState({
    value: formik.values.fromEmail,
    label: `${formik.values.fromName} <${formik.values.fromEmail}>`,
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  useEffect(() => {
    if (formik.values.fromEmail !== selectEmailValue.value) {
      const targetUser = users.find(
        (user) => user.email === formik.values.fromEmail,
      );
      const fromName = targetUser.lastName
        ? `${targetUser?.lastName} ${targetUser?.firstName}`
        : '';
      const label = targetUser.lastName
        ? `${fromName} <${formik.values.fromEmail}>`
        : formik.values.fromEmail;

      formik.setFieldValue('fromName', fromName);
      setSelectEmailValue({
        value: formik.values.fromEmail,
        label: label,
      });
    } else {
      setSelectEmailValue({
        value: formik.values.fromEmail,
        label: `${formik.values.fromName} <${formik.values.fromEmail}>`,
      });
    }
  }, [formik.values.fromName, formik.values.fromEmail]);

  const FiledFromName = () => {
    return (
      <FromNameContainer>
        <h3>差出人</h3>
        <div style={{marginBottom: 18}}>
          <label style={{color: '#899098'}}>差出人名</label>
          <Input
            id="fromName"
            name="fromName"
            defaultValue={formik.values?.fromName}
          />
        </div>
        <div>
          <Button
            style={{marginRight: '10px'}}
            onClick={() => setPopoverVisible(false)}>
            キャンセル
          </Button>
          <Button
            type="primary"
            onClick={() => {
              const fromNameInput: any = document.getElementById('fromName');
              formik.setFieldValue('fromName', fromNameInput.value);
              setPopoverVisible(false);
            }}>
            更新
          </Button>
        </div>
      </FromNameContainer>
    );
  };

  return (
    <Field>
      <label>差出人</label>
      <Select
        bordered={false}
        showSearch={true}
        labelInValue
        value={selectEmailValue}
        onSelect={(value: {value: string; label: string}) => {
          formik.setFieldValue('fromEmail', value.value);
        }}>
        {users.map((user) => (
          <Select.Option key={user.uuid} value={user.email}>
            {user.lastName} {user.firstName}
            {`<${user.email}>`}
          </Select.Option>
        ))}
      </Select>
      <Popover
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={(visible) => setPopoverVisible(visible)}
        placement="bottomRight"
        content={() => <FiledFromName />}>
        <Button type="text" style={{color: '#68B4FB'}}>
          差出人名編集
        </Button>
      </Popover>
    </Field>
  );
};

const Field = styled.div`
  flex: 1;
  min-height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
`;

const FromNameContainer = styled.div`
  width: 315px;
  padding: 12px;

  h3 {
    font-weight: bold;
    font-size: 14px;
  }
  div:last-child {
    display: flex;
    justify-content: end;
  }
`;
